import { Modal } from 'antd';
import { useEffect, useState } from "react";

const View = ({ data, format, isOpen, onClose, fileName }) => {
  const [viewUrl, setViewUrl] = useState("");
  useEffect(() => {
    if (data) {
      const mimeType = {
        ".pdf": "application/pdf",
        ".png": "image/png",
        ".jpeg": "image/jpeg",
        ".jpg": "image/jpeg",
        ".doc": "application/msword",
        ".docx": "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      }[format || ""];

      const type = mimeType || "application/octet-stream";
      const blob = new Blob([data], { type });
      const dataUrl = URL.createObjectURL(blob);
      setViewUrl(dataUrl);

      return () => {
        URL.revokeObjectURL(dataUrl);
      };
    }
  }, [data, format]);

  return (
    <Modal
      title={fileName}
      open={isOpen}
      onCancel={onClose}
      footer={null}
      centered
      width={800}
      bodyStyle={{
        padding: 0,
        height: "80vh",
        overflow: "hidden",
      }}
    >
      {viewUrl && (
        <div style={{ height: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
          {[".png", ".jpeg", ".jpg"].includes(format) ? (
            <img
              src={viewUrl}
              alt="Preview"
              style={{
                maxHeight: "100%",
                maxWidth: "100%",
                objectFit: "contain",
              }}
            />
          ) : (
            <iframe
              src={viewUrl}
              title={fileName}
              style={{
                border: "none",
                width: "100%",
                height: "100%",
              }}
            />
          )}
        </div>
      )}
    </Modal>
  );
};

export default View;
