import React from "react";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "../../../contexts/TranslationContext";
import ContactCard from "components/contactCard/contactCard";
import routes, { appRoute } from "shared/constants/routes";
import { useNavigate } from "react-router-dom";

const ContactUs = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { language } = useTranslation();
  const isRtl = language.dir === "rtl"; // Check if the current language direction is RTL

  const navigateBack = () => {
    navigate(appRoute(routes.SETTING_ROOT));
  }

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="gap-3 flex items-center">
        <div className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10" onClick={navigateBack}>
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-[#161616] text-[22px] font-normal font-sans">
          {translate("SETTINGS.SETTINGS")}
        </div>
      </div>

      <div className="text-black text-2xl font-semibold mt-3 mb-3">
        {translate("SETTINGS.CONTACT_US")}
      </div>

      <p className="text-[#B2B2B2] text-base font-normal mb-6">
        {translate("SETTINGS.CONTACT_DES")}
      </p>

      {/* Grid for Contact Cards */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
        {/* <ContactCard
          title={translate("SETTINGS.CONTACT_CARD1")}
          value="920005477"
        /> */}
        <ContactCard
          title={translate("SETTINGS.CONTACT_CARD2")}
          value="920005477"
        />
        <ContactCard
          title={translate("SETTINGS.CONTACT_CARD3")}
          value="info@tamweel-aloula.com"
        />
      </div>
    </div>
  );
};

export default ContactUs;
