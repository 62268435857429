import React from "react";
import { useTranslation } from "../../../contexts/TranslationContext";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import routes, { appRoute } from "shared/constants/routes";
import { useNavigate } from "react-router-dom";

const TermsAndCondition = () => {
  const { translate } = useTranslation();
  const { language } = useTranslation();
  const navigate = useNavigate();
  const isRtl = language.dir === "rtl";

  const navigateBack = () => {
    navigate(appRoute(routes.SETTING_ROOT));
  }
  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="gap-3 flex items-center">
        <div className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10" onClick={navigateBack}>
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-[#161616] text-[22px] font-normal font-sans">
          {translate("SETTINGS.SETTINGS")}
        </div>
      </div>
      <div className="text-black text-2xl font-semibold mt-3 mb-3">
        {translate("SETTINGS.TERMS_AND_CONDITION")}
      </div>
      <p className="text-sm">{translate("SIGN_UP.TERMS_MODEL.CONTENT")}</p>
    </div>
  );
};

export default TermsAndCondition;
