import React, { useState, useEffect } from "react";
import { useTranslation } from "../../../contexts/TranslationContext";
import {
  ArrowRightOutlined,
  EditOutlined,
  CheckOutlined,
  ArrowLeftOutlined,
} from "@ant-design/icons";
import routes, { appRoute } from "shared/constants/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useAuthHook from "hooks/useAuthHook";
import {
  useGetpersonalInfoMutation,
  useUpdateUserEmailMutation,
} from "services/auth";
import formatDateDDMMYYYY from "utilities/formatDate"

const PersonalDetail = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { language } = useTranslation();

  const isRtl = language.dir === "rtl";
  const [isEditing, setIsEditing] = useState({
    email: false,
  });
  const [formValues, setFormValues] = useState({
    email: "",
    fullName: "",
    arabicFullName: "",
    id: "",
    idExpiryDate: "",
    phone: "",
    gregorianDOB: "",
    hijriDOB: "",
  });
  const { getUser } = useAuthHook();
  const [getpersonalInfo] = useGetpersonalInfoMutation();
  const [updateUserEmail] = useUpdateUserEmailMutation();

  const toggleEdit = async (field) => {
    if (isEditing[field]) {
      if (field === "email") {
        try {
          const user = getUser();
          if (!user) return;
          const requestBody = {
            id: String(user.IDB_USR_ID),
            email: formValues.email,
          };
          await updateUserEmail(requestBody).unwrap();
          toast.success("Email updated successfully.");
        } catch (error) {
          console.error("Failed to update email", error);
          toast.error("Failed to update email.");
        }
      }
    }

    setIsEditing((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

    const handleInputChange = (e, field) => {
      setFormValues((prevState) => ({
        ...prevState,
        [field]: e.target.value,
      }));
    };

    useEffect(() => {
      const fetchUser = async () => {
        try {
          const user = getUser();
          if (!user) return;

          const res = await getpersonalInfo({
            id: String(user?.IDB_USR_ID),
          }).unwrap();
          setFormValues({
            email: res.result.EMAIL || "",
            fullName: `${res.result.FIRST_NAME} ${res.result.MIDDLE_NAME} ${res.result.LAST_NAME} ${res.result.FAMILIY_NAME}`,
            arabicFullName: `${res.result.FIRST_NAME_AR} ${res.result.MIDDLE_NAME_AR} ${res.result.LAST_NAME_AR} ${res.result.FAMILIY_NAME_AR}`,
            id: res.result.USER_ID || "",
            idExpiryDate: formatDateDDMMYYYY(res.result.ID_EXPIRY_DATE),
            phone: res.result.MOBILE || "",
            gregorianDOB: res.result.DOB || "",
            hijriDOB: res.result.DOB_HIJRI || "",
          });
        } catch (error) {
          console.log("Error while fetching personal info", error);
          toast.error("Failed to fetch personal info.");
        }
      };
      fetchUser();
    }, []);

    const navigateBack = () => {
      navigate(appRoute(routes.SETTING_ROOT));
    };

    return (
      <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
        <div className="gap-3 flex items-center">
          <div
            className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
            onClick={navigateBack}
          >
            {isRtl ? (
              <ArrowRightOutlined className="text-black text-lg font-extrabold" />
            ) : (
              <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
            )}
          </div>
          <div className="text-[#161616] text-[22px] font-normal font-sans">
            {translate("SETTINGS.SETTINGS")}
          </div>
        </div>

        <div className="text-black text-2xl font-semibold mt-3 mb-3">
          {translate("SETTINGS.GENERAL_INFO")}
        </div>

        {/* Responsive Container */}
        <div className="mb-5 rounded-[20px] px-5">
          <div className="text-base font-semibold text-[#5F5F5F]">
            {translate("SETTINGS.CUSTOMER_DETAIL")}
          </div>

          {/* First Row: Full Name, Arabic Name, ID */}
          <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Full Name */}
            <div className={isRtl ? "text-end w-full" : ""}>
              <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                Full Name
              </div>
              <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                {formValues.fullName}
              </div>
            </div>

            {/* Arabic Full Name */}
            <div className="flex items-start md:flex-row flex-col">
              <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
              <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
              <div className={isRtl ? "md:mr-2" : "md:ml-2 text-end w-full"}>
                <div className="text-sm font-normal text-[#5F5F5F]">
                  الاسم الكامل
                </div>
                <div className="text-sm font-normal text-[#5F5F5F]">
                  {formValues.arabicFullName}
                </div>
              </div>
            </div>
            {/* ID */}
            <div className="flex items-start md:flex-row flex-col">
              <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
              <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
              <div className={isRtl ? "md:mr-2" : "md:ml-2"}>
                <div className="text-sm font-normal text-[#5F5F5F]">
                  {translate("CREATE_REQUEST.ID")}
                </div>
                <div className="text-sm font-normal text-[#5F5F5F]">
                  {formValues.id}
                </div>
              </div>
            </div>
          </div>

          <hr className="my-4 border-[#BCBDCE]" />

          {/* Second Row: ID Expiry Date, Phone, Email */}
          <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* ID Expiry Date */}
            <div className="flex items-start md:flex-row flex-col">
              <div>
                <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                  {translate("CREATE_REQUEST.ID_Expiry_Date")}
                </div>
                <div className="font-calibri text-sm font-normal text-[#DC2626]">
                  {formValues.idExpiryDate}
                </div>
              </div>
            </div>

            {/* Phone */}
            <div className="flex items-start md:flex-row flex-col">
              <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
              <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
              <div className={isRtl ? "md:mr-2" : "md:ml-2"}>
                <div className="text-sm font-normal text-[#5F5F5F]">
                  {translate("CREATE_REQUEST.Phone")}
                </div>
                <div className="text-sm font-normal text-[#5F5F5F]">
                  {formValues.phone}
                </div>
              </div>
            </div>

            {/* Email */}
            <div className="flex items-start md:flex-row flex-col">
              <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
              <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
              <div className="flex justify-between w-full">
                {/* Flex container to position text and icon */}
                <div
                  className={isRtl ? "md:mr-2" : "md:ml-2"}
                  style={{ width: "100%" }}
                >
                  {isEditing.email ? (
                    <input
                      type="text"
                      value={formValues.email}
                      onChange={(e) => handleInputChange(e, "email")}
                      className="border border-gray-300 p-2 rounded-xl w-full text-gray-400 outline-none focus:ring-0"
                      // Make the input take over the entire space (label and value)
                    />
                  ) : (
                    <div>
                      <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                        {translate("CREATE_REQUEST.Email")}
                      </div>
                      <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                        {formValues.email}
                      </div>
                    </div>
                  )}
                </div>
                {isEditing.email ? (
                  <CheckOutlined
                    className={`text-[#36B289] ${isRtl ? "mr-2" : "ml-2"}`}
                    onClick={() => toggleEdit("email")}
                  />
                ) : (
                  <EditOutlined
                    className={`text-gray-500 ${isRtl ? "mr-2" : "ml-2"}`}
                    onClick={() => toggleEdit("email")}
                  />
                )}
              </div>
            </div>
          </div>

          <hr className="my-4 border-[#BCBDCE]" />

          {/* Third Row: Gregorian Date, Hijri Date */}
          <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            {/* Gregorian Date */}
            <div>
              <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                {translate("SETTINGS.GEORGIAN_DOB")}
              </div>
              <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                {formValues.gregorianDOB}
              </div>
            </div>

            {/* Hijri Date */}
            <div className="flex items-start md:flex-row flex-col">
              <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
              <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
              <div className={isRtl ? "md:mr-2" : "md:ml-2"}>
                <div className="text-sm font-normal text-[#5F5F5F]">
                  {translate("SETTINGS.HIJRI_DOB")}
                </div>
                <div className="text-sm font-normal text-[#5F5F5F]">
                  {formValues.hijriDOB}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

export default PersonalDetail;
