const routes = {
  ACCESS_DENIED: "/access-denied",
  NOT_FOUND: "/not-found",
  AUTH_ROOT: "auth",
  AUTH: {
    FORGOT_PASSWORD: "/auth/forgot-password",
    SIGN_IN: "/auth/sign-in",
    SIGN_UP: "/auth/sign-up",
    ACCEPT_TERMS_AND_COND: "/auth/accept-terms",
  },
  APP_ROOT: "app",
  HOME: "home",

  SETTING_ROOT: "setting",
  SETTING: {
    PERSONAL_DETAIL: "setting/personal-detail",
    ADDRESS_DETAIL: "setting/address-detail",
    MARITAL_STATUS: "setting/marital-status",
    TERMS_AND_CONDITION: "setting/terms-and-condition",
    ELIGIBILITY: "setting/eligibility",
    CONTACT_US: "setting/contact-us",
    DOCUMENT: "setting/document",
    FAQ: "setting/faq",
    CHANGE_PASSWORD: "setting/change-password",
  },
  CREATE_REQUEST: "create-request",
  CREATE_REQUEST_PERSONAL_DETAILS: "personal-details",
  CREATE_REQUEST_EMPLOYMENT_DETAILS: "employment-details",
  CREATE_REQUEST_FINANCIAL_DETAILS: "financial-details",
  CREATE_REQUEST_LOAN_DETAILS: "loan-details",
  CREATE_REQUEST_AUTO_LOAN: "auto-loan",
  CREATE_REQUEST_DOCUMENT_DETAILS: "document-details",
  CREATE_REQUEST_GUARANTORS_DETAILS: "guarantors-details",
  CREATE_REQUEST_SUCCESS: "success",
  REQUEST_DETAILS: "request-details",
  VIEW_REQUEST: "view-request",
  VIEW_OFFER: "view-offer",
  VIEW_CONTRACT: "view-contract",
};

export const appRoute = (route) => `/${routes.APP_ROOT}/${route}`;

export const authRoute = (route) => `/${routes.AUTH_ROOT}/${route}`;

export const createRequestRoute = (route) =>
  `/${routes.APP_ROOT}/${routes.CREATE_REQUEST}/${route}`;

export default routes;
