import React from "react";
import Phone from "assets/icons/Phone.svg";

const ContactCard = ({ title, value }) => {
  return (
    <div className="rounded-lg flex justify-between items-center border border-[#DDDFDF] py-2 px-4 shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div>
        <div className="text-[#7A7A9D] font-semibold text-sm">
          {title}
        </div>
        <div className="text-[#27272E] text-xl font-semibold">
          {value}
        </div>
      </div>
      <div
        className="flex items-center justify-center h-[42px] w-[42px] rounded-2xl"
        style={{ backgroundColor: "#50C87854" }}
      >
        <img src={Phone} alt="Phone" className="h-5 w-5" />
      </div>
    </div>
  );
};

export default ContactCard;
