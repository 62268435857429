import React, { useState } from "react";
import { LeftOutlined, RightOutlined, DownOutlined } from "@ant-design/icons";
import { useTranslation } from "../../contexts/TranslationContext";

const FAQ_Card = ({ question, answer }) => {
  const { language } = useTranslation();
  const isRtl = language.dir === "rtl"; // Check if the current language direction is RTL

  const [isOpen, setIsOpen] = useState(false); // State to track if the FAQ is open

  const handleClick = () => {
    setIsOpen(!isOpen); // Toggle FAQ open/close
  };

  return (
    <div className="rounded-lg border border-[#DDDFDF] py-5 px-4 mb-3">
      <div className="flex justify-between items-center">
        <p className="text-[#616161] font-bold text-lg">{question}</p>
        <div
          className={`rounded-full py-2 px-3 cursor-pointer transition-colors duration-300 ${
            isOpen ? "bg-primary" : "bg-[#F3EDDD]"
          }`}
          onClick={handleClick}
        >
          {isOpen ? (
            <DownOutlined className="text-white text-base font-semibold" />
          ) : isRtl ? (
            <LeftOutlined className="text-[#7F7F7F] text-base font-semibold" />
          ) : (
            <RightOutlined className="text-[#7F7F7F] text-base font-semibold" />
          )}
        </div>
      </div>
      {isOpen && (
        <p className="mt-4 text-[#616161] text-lb font-normal">
          {answer}
        </p>
      )}
    </div>
  );
};

export default FAQ_Card;



