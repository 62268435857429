import {
  AutoComplete,
  Button,
  Col,
  Input,
  InputNumber,
  Row,
  Select,
  notification,
  DatePicker,
} from "antd";
import { Form, useField, useFormikContext, withFormik } from "formik";
import { useEffect, useState } from "react";
import * as Yup from "yup";
import { SearchOutlined } from "@ant-design/icons";
import { useTranslation } from "./contexts/TranslationContext";
import dayjs from "dayjs";

const Label = ({ name, label, required = false }) =>
  label && (
    <label htmlFor={name} className="flex gap-2 mb-2 text-sm">
      <span>{label}</span>
      {required && <span className="text-red-500">*</span>}
    </label>
  );

const ErrorMessage = ({ error, touched }) =>
  error && touched && <div className="text-sm text-red-500 mt-2">{error}</div>;

const AutoCompleteControl = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const [options, setOptions] = useState(props?.options || []);
  const [filteredOptions, setFilteredOptions] = useState(props?.options || []);
  const [isTyping, setIsTyping] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const { translate } = useTranslation();

  const onSelect = (data, option) => {
    helpers.setValue(option.value);
    // setSelectedOption(option.value);
    setInputValue(option.label);
  };

  const onChange = (data, option) => {
    // setInputValue(data);
    // setSelectedOption(option); // to remove selected option when user types  something wich doesn't match with any option
  };

  return (
    <>
      <Label
        label={props?.label}
        name={props?.name}
        required={props?.required}
      />
      <AutoComplete
        className="w-full min-h-12"
        name={props.name}
        id={props.name}
        status={meta.error && meta.touched ? "error" : null}
        placeholder={props.label}
        disabled={props?.disabled}
        options={options}
        onSelect={onSelect}
        onChange={onChange}
        value={inputValue}
        filterOption={(inputValue, option) =>
          option.labelAr.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1
        }
        suffixIcon={<SearchOutlined />}
        {...field}
      />
      <ErrorMessage error={meta.error} touched={meta.touched} />
    </>
  );
};

const TextControl = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const { translate } = useTranslation();

  /*   useEffect(() => {
	// Setting the default value for the field

	helpers.setValue(props.defaultValue);
  }, [props.defaultValue]); */
  return (
    <>
      <Label
        label={translate("CREATE_REQUEST." + props?.label)}
        name={props?.name}
        required={props?.required}
      />
      <Input
        className="w-full min-h-12"
        name={props.name}
        maxLength={props?.maxLength ?? 255}
        id={props.name}
        status={meta.error && meta.touched ? "error" : null}
        placeholder={translate("CREATE_REQUEST." + props.label)}
        disabled={props?.disabled}
        addonBefore={props?.addonBefore}
        {...field}
      />
      <ErrorMessage
        error={meta.error && translate("CREATE_REQUEST." + meta.error)}
        touched={meta.touched}
      />
    </>
  );
};

const NumberControl = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const { translate } = useTranslation();

  const handleChange = (value) => {
    helpers.setValue(String(value));
  };
  /*   useEffect(() => {
	// Setting the default value for the field

	helpers.setValue(props.defaultValue);
  }, [props.defaultValue]); */
  return (
    <>
      <Label
        label={translate("CREATE_REQUEST." + props?.label)}
        name={props?.name}
        required={props?.required}
      />
      <InputNumber
        type="number"
        className="w-full min-h-12"
        name={props.name}
        id={props.name}
        status={meta.error && meta.touched ? "error" : null}
        placeholder={translate("CREATE_REQUEST." + props.label)}
        disabled={props?.disabled}
        min={0}
        {...field}
        onChange={handleChange}
        addonBefore={props?.addonBefore}
        onInput={handleChange}
      />
      <ErrorMessage
        error={meta.error && translate("CREATE_REQUEST." + meta.error)}
        touched={meta.touched}
      />
    </>
  );
};

const SelectControl = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const { translate, language } = useTranslation();

  const handleChange = (value) => {
    helpers.setValue(String(value));
    helpers.setTouched(true); //so that meta.touched becomes true
  };
  const handleSelect = (value) => {
    helpers.setValue(String(value));
    if (props?.label === "Tenure") {
      helpers.setTouched(true); //so that meta.touched becomes true
    }
  };
  let options = props?.options || [];
  if (language.code === "ar") {
    options = options.map((opt) => {
      return {
        label: opt.labelAr ?? opt.label,
        value: opt.value,
      };
    });
  }

  return (
    <>
      <Label
        label={translate("CREATE_REQUEST." + props?.label)}
        name={props?.name}
        required={props?.required}
      />
      <Select
        name={props.key}
        id={props.key}
        className="w-full min-h-12"
        status={meta.error && meta.touched ? "error" : null}
        options={options}
        onChange={handleChange}
        onSelect={handleSelect}
        onClear={() => helpers.setValue(null)}
        value={field.value}
        showSearch={true}
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        placeholder={translate("CREATE_REQUEST." + props.label)}
        disabled={props?.disabled}
        {...field}
      />
      <ErrorMessage
        error={meta.error && translate("CREATE_REQUEST." + meta.error)}
        touched={meta.touched}
      />
    </>
  );
};
const RadioControl = (props) => {
  const [field, meta, helpers] = useField(props.name);
  const { translate, language } = useTranslation();
  const isRtl = language.dir === "rtl"; // Check if the current language direction is RTL
  let options = props?.options || [];
  if (language.code === "ar") {
    options = options.map((opt) => {
      return {
        label: opt.labelAr ?? opt.label,
        value: opt.value,
      };
    });
  }
  return (
    <>
      <Label
        label={translate("CREATE_REQUEST." + props?.label)}
        name={props?.name}
        required={props?.required}
      />
      {/* Adjust flex and spacing */}
      <div
        className={`flex ${isRtl ? "space-x-reverse space-x-4" : "space-x-4"}`}
      >
        {options.map((option) => (
          <div
            key={option.value}
            className={`border ${
              field.value === option.value
                ? "border-primary"
                : "border-[#CDCDCD]"
            } rounded-md py-3  cursor-pointer flex items-center ${isRtl ? "pr-2 pl-10" : "pr-10 pl-2"}`} // Adjusted padding for uniformity
            onClick={() => {
              helpers.setValue(option.value);
            }}
          >
            <div className="flex items-center">
              {/* Custom Radio Button */}
              <div
                className={`relative w-4 h-4 rounded-full border ${
                  field.value === option.value
                    ? "border-primary bg-primary"
                    : "border-[#CDCDCD]"
                } flex items-center justify-center cursor-pointer`}
              >
                {field.value === option.value && (
                  <div className="w-2 h-2 rounded-full bg-white" />
                )}
              </div>

              {/* Label */}
              <label
                className={`font-calibri text-sm ${
                  field.value === option.value
                    ? "text-primary"
                    : "text-[#5F5F5F]"
                } cursor-pointer ${isRtl ? "mr-2" : "ml-2"}`} // Adjust margin between button and label
              >
                {translate(option.label)}
              </label>
            </div>
          </div>
        ))}
      </div>
    </>
  );
};

const DatePickerControl = (props) => {
	const [field, meta, helpers] = useField(props.name);
	const { translate } = useTranslation();

  const handleChange = (value, formattedValue) => {
    helpers.setValue(String(formattedValue));
  };

  const disableFutureDates = (current) => {
    return current && current > dayjs().endOf("day");
  };

  // Conditional styling for placeholder based on field.value
  const placeholderStyle = field.value
    ? { color: "black" } // Text color for filled date value
    : { color: "#BFBFBF" }; // Default placeholder color when value is null

  return (
    <>
      <Label
        label={translate(props?.label)}
        name={props?.name}
        required={props?.required}
      />
      <DatePicker
        format="DD/MM/YYYY"
        className="w-full min-h-12"
        disabled={field?.disabled}
        disabledDate={disableFutureDates}
        placeholder={translate(props.placeholder || field.label)}
        onChange={handleChange}
        value={field.value ? dayjs(field.value, "DD/MM/YYYY") : null}
        style={placeholderStyle} // Apply conditional placeholder style
      />
    </>
  );
};
const FormField = ({ field }) => {
  return (
    <div className={`w-full ${field.newRow ? "col-span-full" : ""}`}>
      {/* Render different controls based on the field type */}
      {(() => {
        switch (field.type) {
          case "auto-complete":
            return (
              <AutoCompleteControl
                name={field.key}
                label={field.label}
                required={field?.required}
                disabled={field?.disabled}
                options={field?.options || []}
              />
            );
          case "text":
            return (
              <TextControl
                maxLength={field?.props?.maxLength ?? 255}
                name={field.key}
                label={field.label}
                required={field?.required}
                disabled={field?.disabled}
                addonBefore={field?.addonBefore}
              />
            );
          case "select":
            return (
              <SelectControl
                name={field.key}
                label={field.label}
                required={field?.required}
                disabled={field?.disabled}
                options={field?.options || []}
                {...field.props}
                />
              );
              case "number":
                return (
              <NumberControl
              name={field.key}
              label={field.label}
              required={field?.required}
              disabled={field?.disabled}
              />
            );
            case "radio":
              return (
                <RadioControl
                name={field.key}
                label={field.label}
                required={field?.required}
                options={field?.options || []}
                {...field.props}
              />
            );
          case "datePicker":
            return (
              <DatePickerControl
                name={field.key}
                label={field.label}
                required={field?.required}
                disabled={field?.disabled}
              />
            );
          default:
            return <></>;
        }
      })()}
    </div>
  );
};

export const FormDrawer = ({ schema }) => {
  return (
    <Row gutter={[16, 16]}>
      {schema.fields.map((field, i) => (
        <Col
          xs={24}
          lg={field.newRow ? 24 : field?.span || 12} // Use newRow to control full width, otherwise use span or default 12
          key={i}
        >
          <FormField field={field} />
        </Col>
      ))}
    </Row>
  );
};

const genderOptions = [
  { label: "Male", value: "Male" },
  { label: "Female", value: "Female" },
];

const dependentsOptions = [
  { label: "Yes", value: "1" },
  { label: "No", value: "0" },
];

const inquiryFormSchemaFields = (hasDependents = true) => [
  {
    type: "text",
    name: "name",
    label: "YOUR_NAME",
    required: true,
    validation: Yup.string().required("PLEASE_ENTER_NAME"),
  },
  {
    type: "text",
    name: "email",
    label: "YOUR_EMAIL",
    required: true,
    validation: Yup.string()
      .email("USE_VALID_EMAIL")
      .required("PLEASE_TYPE_EMAIL"),
  },
  {
    type: "select",
    name: "gender",
    label: "YOUR_GENDER",
    required: true,
    options: genderOptions,
    validation: Yup.string().required("PLEASE_SELECT_GENDER"),
  },
  {
    type: "radio",
    name: "hasDependents",
    label: "DO_YOU_HAVE_DEPENDENTS",
    options: dependentsOptions,
    value: "0",
    validation: Yup.string(),
  },
  ...(hasDependents
    ? [
        {
          type: "number",
          name: "numberOfDependents",
          label: "NUMBER_OF_DEPENDENTS",
          validation: Yup.string(),
        },
      ]
    : []),
];

const SubmitOnEasterEgg = () => {
  const { values } = useFormikContext();

  useEffect(() => {
    if (values?.name === "Formik") {
      notification.info({ message: "You've discovered an Easter Egg." });
    }
  }, [values]);

  return null;
};

export const buildValidationSchema = (fields) =>
  Yup.object().shape(
    fields.reduce(
      (fields, field) => ({
        ...fields,
        [field.key]: field?.validation || Yup.string(),
      }),
      {}
    )
  );

export const buildInitialValues = (fields) =>
  fields.reduce(
    (fields, field) => ({
      ...fields,
      [field.key]: field?.value,
    }),
    {}
  );

const InquiryForm = withFormik({
  mapPropsToValues: () =>
    inquiryFormSchemaFields().reduce(
      (fields, field) => ({
        ...fields,
        [field.name]: field?.value || "",
      }),
      {}
    ),
  validationSchema: Yup.object().shape(
    inquiryFormSchemaFields().reduce(
      (fields, field) => ({
        ...fields,
        [field.name]: field.validation,
      }),
      {}
    )
  ),
  handleSubmit(values) {
    console.log(values);
  },
})(function InternalInquiryForm(props) {
  return (
    <Form>
      <FormDrawer
        schema={{
          fields: inquiryFormSchemaFields(props.values.hasDependents === "1"),
        }}
      />
      <Button
        htmlType="button"
        onClick={() => {
          console.log(props?.values);
        }}
      >
        OK
      </Button>
      <SubmitOnEasterEgg />
    </Form>
  );
});

const Components = () => {
  return (
    <>
      <div className="container px-4 mx-auto">
        <h1 className="text-2xl font-bold rounded-md my-4 p-4 bg-gray-100">
          Components
        </h1>
        <InquiryForm />
      </div>
    </>
  );
};

export { Components };
