import { useTranslation } from "../../../contexts/TranslationContext";
import DocumentCard from "components/documentCard/documentCard";
import { ArrowRightOutlined, ArrowLeftOutlined, LoadingOutlined } from "@ant-design/icons";
import routes, { appRoute } from "shared/constants/routes";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import View from "./view";
import useAuthHook from "hooks/useAuthHook";
import { useGetDocumentsQuery } from "services/auth";
import { useState } from "react";
import formatDateDDMMYYYY from "utilities/formatDate"

const DocumentList = () => {
  const { translate, language } = useTranslation();
  const navigate = useNavigate();
  const isRtl = language.dir === "rtl";
  const [selectedFile, setSelectedFile] = useState(null);
  const [isViewing, setIsViewing] = useState(false);
  const { getUser } = useAuthHook();

  const user = getUser();
  const userId = user ? String(user.IDB_USR_ID) : null;

  // Use the query hook directly
  const { data, isLoading, isError } = useGetDocumentsQuery(userId, { skip: !userId, refetchOnMountOrArgChanges: true });

  // Handle any errors with toast notification
  if (isError) {
    toast.error("Failed to fetch documents. Please check network and API.");
  }

  const navigateBack = () => {
    navigate(appRoute(routes.SETTING_ROOT));
  };

  const handleViewDocument = (file) => {
    setSelectedFile(file);
    setIsViewing(true);
  };

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="gap-3 flex items-center">
        <div
          className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
          onClick={navigateBack}
        >
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-[#161616] text-[22px] font-normal font-sans">
          {translate("SETTINGS.SETTINGS")}
        </div>
      </div>
      <div className="text-black text-2xl font-semibold mt-3 mb-3">
        {translate("SETTINGS.DOCUMENT")}
      </div>

      {isLoading ? (
        <div className="p-5 bg-white flex flex-col gap-4">
          <div className="flex gap-4">
            <LoadingOutlined />
            <span>{translate("SETTINGS.LOADING_DOCUMENT")}</span>
          </div>
        </div>
      ) : data?.payload?.length > 0 ? (
        <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
          {data.payload.map((file, index) => (
            <DocumentCard
              key={index}
              date={formatDateDDMMYYYY(file.createdAt)}
              documentName={file.fileName}
              onClick={() => handleViewDocument(file)}
            />
          ))}
        </div>
      ) : (
        <div className="p-5 bg-white text-center text-gray-500">
          {translate("SETTINGS.NO_DOCUMENTS_AVAILABLE")}
        </div>
      )}
      <View
        data={selectedFile ? Uint8Array.from(selectedFile.data.data) : null}
        fileName={selectedFile?.fileName}
        format={selectedFile?.format}
        isOpen={isViewing}
        onClose={() => setIsViewing(false)}
      />
    </div>
  );
};

export default DocumentList;
