import React from "react";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import Card from "components/SettingsCards/Card";
import { useTranslation } from "../../../contexts/TranslationContext";
import Profile from "assets/icons/Profile.svg";
import MapPoint from "assets/icons/MapPoint.svg";
import MaritialStatus from "assets/icons/MaritialStatus.svg";
import Notes from "assets/icons/Notes.svg";
import Documents from "assets/icons/Documents.svg";
import Terms from "assets/icons/Terms.svg";
import Question from "assets/icons/Question.svg";
import Phone from "assets/icons/Phone.svg";
import Lock from "assets/icons/Lock.svg";
import routes, { appRoute } from "shared/constants/routes";
import { useNavigate } from "react-router-dom";

const Setting = () => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { language } = useTranslation();
  const isRtl = language.dir === "rtl"; // Check if the current language direction is RTL

  // Single function to handle all navigations
  const navigateTo = (route) => {
    navigate(appRoute(route));
  };

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="gap-3 flex items-center">
        <div
          className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
          onClick={() => navigateTo(routes.HOME)}
        >
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-[#161616] text-[22px] font-normal font-sans">
          {translate("SETTINGS.SETTINGS")}
        </div>
      </div>
      {/* Responsive grid layout */}
      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-y-4 gap-x-10 mt-4 w-full px-10">
        <Card
          bgColor="#006E7EB2"
          svg={<img src={Profile} alt="Profile" />}
          title={translate("SETTINGS.PERSONAL_DATA")}
          description={translate("SETTINGS.PERSONAL_DATA_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.PERSONAL_DETAIL)} // Pass route to the function
        />
        <Card
          bgColor="#FFC243B2"
          svg={<img src={MapPoint} alt="Map" />}
          title={translate("SETTINGS.ADDRESS_DETAILS")}
          description={translate("SETTINGS.ADDRESS_DETAILS_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.ADDRESS_DETAIL)}
        />
        <Card
          bgColor="#BCBDCE"
          svg={<img src={MaritialStatus} alt="Maritial Status" />}
          title={translate("SETTINGS.MARITAL_STATUS")}
          description={translate("SETTINGS.MARITAL_STATUS_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.MARITAL_STATUS)}
        />
        <Card
          bgColor="#A1B8E4"
          svg={<img src={Lock} alt="Reset Password" />}
          title={translate("SETTINGS.CHANGE_PASSWORD")}
          description={translate("SETTINGS.CHANGE_PASSWORD_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.CHANGE_PASSWORD)}
        />
        <Card
          bgColor="#919094"
          svg={<img src={Notes} alt="Notes" />}
          title={translate("SETTINGS.ELIGIBILITY")}
          description={translate("SETTINGS.ELIGIBILITY_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.ELIGIBILITY)}
        />
        <Card
          bgColor="#C1D3FF"
          svg={<img src={Documents} alt="Documents" />}
          title={translate("SETTINGS.DOCUMENT")}
          description={translate("SETTINGS.DOCUMENT_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.DOCUMENT)}
        />
        <Card
          bgColor="#6E1313B5"
          svg={<img src={Question} alt="FAQ" />}
          title={translate("SETTINGS.FAQ")}
          description={translate("SETTINGS.FAQ_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.FAQ)}
        />
        <Card
          bgColor="#50C87854"
          svg={<img src={Phone} alt="Phone" />}
          title={translate("SETTINGS.HELP_AND_SUPPORT")}
          description={translate("SETTINGS.HELP_AND_SUPPORT_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.CONTACT_US)}
        />
        <Card
          bgColor="#FF88004D"
          svg={<img src={Terms} alt="Terms" />}
          title={translate("SETTINGS.TERMS_AND_CONDITION")}
          description={translate("SETTINGS.TERMS_AND_CONDITION_DES")}
          onArrowClick={() => navigateTo(routes.SETTING.TERMS_AND_CONDITION)}
        />
      </div>
    </div>
  );
};

export default Setting;
