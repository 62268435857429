import React from "react";
import { CalendarOutlined } from "@ant-design/icons";
import { Button } from "shared/components/Button";
import { useTranslation } from "../../contexts/TranslationContext";

const DocumentCard = ({ date, documentName, onClick }) => {
  const { translate, language } = useTranslation();
  const isRtl = language.dir === "rtl";
  
  return (
    <div className="rounded-lg items-center border border-[#DDDFDF] py-2 px-4">
      <div className="flex text-[#908BA6] text-sm font-normal">
        <CalendarOutlined />
        <p className={`${isRtl ? "mr-2" : "ml-2"}`}>{date}</p>
      </div>
      <h2 
        className="text-[#333333] text-2xl font-semibold mt-1 overflow-hidden whitespace-nowrap text-ellipsis"
        style={{ maxWidth: "250px" }} // Adjust width as needed
      >
        {documentName}
      </h2>
      <Button
        onClick={onClick}
        color="primary"
        className="rounded-lg h-10 w-36 items-center justify-center flex mt-4"
      >
        {translate("SETTINGS.VIEW")}
      </Button>
    </div>
  );
};

export default DocumentCard;
