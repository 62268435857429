import React from "react";
import { RightOutlined, LeftOutlined } from "@ant-design/icons";
import { useTranslation } from "../../contexts/TranslationContext";

const Card = ({
  bgColor,
  title,
  svg, // Now accepting an SVG element instead of an icon
  description,
  onArrowClick, // Function to handle the click on the arrow
}) => {
  const { language } = useTranslation(); // Get the current language and its properties
  const isRtl = language.dir === 'rtl'; // Check if the current language direction is RTL

  return (
    <div className="flex items-center justify-between border border-[#DDDFDF] rounded-xl py-2 px-3 gap-x-5 shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)] cursor-pointer" onClick={onArrowClick}>
      <div className="flex items-center gap-x-5">
        <div className={`items-center justify-center flex h-[42px] w-[42px] rounded-2xl`} style={{ backgroundColor: bgColor }}>
          <div className="h-10 w-10 items-center justify-center flex">
            {svg}
          </div>
        </div>
        <div className="items-center">
          <div className="text-[#1D1E25] text-base font-bold ">{title}</div>
          <div className="text-black text-xs font-light ">
            {description}
          </div>
        </div>
      </div>
      {/* Arrow with onClick event */}
      <div className="flex items-center" >
        {isRtl ? <LeftOutlined /> : <RightOutlined />}
      </div>
    </div>
  );
};

export default Card;
