import React, { useState, useEffect, useRef } from "react";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  EyeOutlined,
  EyeInvisibleOutlined,
} from "@ant-design/icons";
import { Form, Field, ErrorMessage, withFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "../../../contexts/TranslationContext";
import { Modal, Row, Col } from "antd";
import { Button } from "shared/components/Button";
import routes, { appRoute } from "shared/constants/routes";
import { useNavigate } from "react-router-dom";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import { toast } from "react-toastify";
import {
  useCheckPasswordMutation,
  useResetPasswordMutation,
} from "services/auth";
import hashedPassword from "utilities/hashedPassword";
import Error from "assets/icons/Error.svg";

const passwordSchemaFields = (translate) => [
  {
    type: "password",
    name: "oldPassword",
    label: "OLD_PASSWORD",
    required: true,
    validation: Yup.string().required(
      translate("SETTINGS.PLEASE_ENTER_OLD_PASSWORD")
    ),
  },
  {
    type: "password",
    name: "newPassword",
    label: "NEW_PASSWORD",
    required: true,
    validation: Yup.string().required(
      translate("SETTINGS.PLEASE_ENTER_NEW_PASSWORD")
    ),
  },
  {
    type: "password",
    name: "confirmPassword",
    label: "CONFIRM_PASSWORD",
    required: true,
    validation: Yup.string()
      .oneOf(
        [Yup.ref("newPassword"), null],
        translate("SETTINGS.PASSWORDS_MUST_MATCH")
      )
      .required(translate("SETTINGS.PLEASE_CONFIRM_PASSWORD")),
  },
];

const ChangePasswordForm = withFormik({
  mapPropsToValues: (props) =>
    passwordSchemaFields(props.translate).reduce(
      (fields, field) => ({
        ...fields,
        [field.name]: field?.value || "",
      }),
      {}
    ),
  validationSchema: (props) =>
    Yup.object().shape(
      passwordSchemaFields(props.translate).reduce(
        (fields, field) => ({
          ...fields,
          [field.name]: field.validation,
        }),
        {}
      )
    ),
  handleSubmit: async (values, { props, setSubmitting, resetForm }) => {
    const {
      setNewPassword,
      setIsOtpModalVisible,
      checkPassword,
      resetCountdown,
    } = props;

    try {
      const response = await checkPassword({
        password: hashedPassword(values.oldPassword),
      }).unwrap();
      if (response.passwordUpdate === false) {
        toast.error("Current password is incorrect.");
        resetForm();
        setSubmitting(false);
        return;
      }
      toast.success("Current password verified.");
      setNewPassword(values.newPassword);
      setIsOtpModalVisible(true);
      resetCountdown();
      resetForm();
    } catch (error) {
      toast.error("Invalid current password.");
    } finally {
      setSubmitting(false);
    }
  },
})(function InternalForm(props) {
  const { translate, language, showPassword, togglePasswordVisibility } = props;
  const { handleSubmit, touched, errors } = props;
  const navigate = useNavigate();

  const navigateBack = () => {
    navigate(appRoute(routes.SETTING_ROOT));
  };

  return (
    <Form onSubmit={handleSubmit} className="space-y-6">
      {passwordSchemaFields(translate).map((field) => (
        <div key={field.name} className="flex flex-col sm:w-full lg:w-1/3">
          <label
            htmlFor={field.name}
            className="text-[#2A353D] mb-2 text-base font-normal"
          >
            {translate(`SETTINGS.${field.label}`)}{" "}
            {field.required && <span className="text-red-500">*</span>}
          </label>
          <div className="relative">
            <Field
              type={showPassword[field.name] ? "text" : "password"}
              name={field.name}
              id={field.name}
              placeholder="********"
              className={`border rounded-xl w-full focus:outline-none focus:ring-0 ${
                touched[field.name] && errors[field.name]
                  ? "border-red-500"
                  : "border-gray-300"
              }`}
              style={{
                color: "#2A353D",
                fontFamily: "sans-serif",
                fontWeight: "400",
                fontSize: "15px",
                height: "48px", // Set height for input field
                paddingTop: "12px", // Vertical padding for centering
                paddingBottom: "12px", // Vertical padding for centering
                paddingLeft: language.dir === "rtl" ? "40px" : "12px", // Adjust padding based on language direction
                paddingRight: language.dir === "rtl" ? "12px" : "40px", // Adjust padding for the icon
                boxSizing: "border-box",
              }}
            />
            <span
              className={`absolute inset-y-0 flex items-center cursor-pointer ${
                language.dir === "rtl" ? "left-3" : "right-3"
              }`}
              onClick={() => togglePasswordVisibility(field.name)}
            >
              {showPassword[field.name] ? (
                <EyeOutlined />
              ) : (
                <EyeInvisibleOutlined />
              )}
            </span>
          </div>
          <div className="flex items-center">
            {errors[field.name] && touched[field.name] && (
              <img src={Error} alt="Error icon" className=" mt-1 mr-2" />
            )}
            <ErrorMessage
              name={field.name}
              component="div"
              className="text-red-500 text-sm mt-1"
            />
          </div>
        </div>
      ))}

      <div className="flex items-center justify-end gap-2">
        <Button
          onClick={navigateBack}
          color="primary"
          mode="outline"
          className="rounded-xl h-12 w-32"
        >
          {translate("CREATE_REQUEST.CANCEL")}
        </Button>
        <Button
          color="primary"
          type="primary"
          htmlType="submit"
          loading={props.isSubmitting}
          className="rounded-xl h-12 w-64"
        >
          {translate("SETTINGS.SUBMIT")}
        </Button>
      </div>
    </Form>
  );
});

const ChangePassword = () => {
  const { translate, language } = useTranslation();
  const navigate = useNavigate();
  const isRtl = language.dir === "rtl";
  const [isOtpModalVisible, setIsOtpModalVisible] = useState(false);
  const [otp, setOtp] = useState(["", "", "", ""]);
  const [seconds, setSeconds] = useState(60);
  const [newPassword, setNewPassword] = useState("");
  const [showPassword, setShowPassword] = useState({
    oldPassword: false,
    newPassword: false,
    confirmPassword: false,
  });
  const [checkPassword] = useCheckPasswordMutation();
  const [resetPassword] = useResetPasswordMutation();

  const otpRefs = [useRef(), useRef(), useRef(), useRef()];

  const togglePasswordVisibility = (field) =>
    setShowPassword((prev) => ({ ...prev, [field]: !prev[field] }));

  const resetCountdown = () => setSeconds(60);
  const resetOtp = () => setOtp(["", "", "", ""]);

  const handleOtpSubmit = async () => {
    const otpCode = otp.join("");
    if (otpCode.length < 4) {
      toast.error("Please enter the complete OTP.");
      return;
    }

    try {
      const user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));
      const response = await resetPassword({
        phone: user?.MOBILE,
        otp: otpCode,
        password: hashedPassword(newPassword),
      }).unwrap();

      if (response?.passwordUpdate) {
        toast.success("Password reset successfully.");
        setIsOtpModalVisible(false);
        resetOtp();
      } else {
        toast.error(
          response?.message || "Failed to reset password. Try again."
        );
        resetOtp();
      }
    } catch (error) {
      toast.error("OTP invalid or password reset failed.");
      resetOtp();
    }
  };

  const handleChange = (value, index) => {
    if (value.length <= 1) {
      setOtp((prevOtp) =>
        prevOtp.map((char, i) => (i === index ? value : char))
      );
      if (value && index < otpRefs.length - 1)
        otpRefs[index + 1].current.focus();
    }
  };

  useEffect(() => {
    if (isOtpModalVisible) {
      const intervalId = setInterval(() => {
        setSeconds((prev) => (prev > 0 ? prev - 1 : 0));
        if (seconds <= 1) {
          toast.error("OTP timeout. Please try again.");
          setIsOtpModalVisible(false);
          resetOtp();
        }
      }, 1000);
      return () => clearInterval(intervalId);
    }
  }, [isOtpModalVisible, seconds]);

  const navigateBack = () => {
    navigate(appRoute(routes.SETTING_ROOT));
  };

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow">
      <div className="gap-3 flex items-center">
        <div
          className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
          onClick={navigateBack}
        >
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-[#161616] text-[22px] font-normal font-sans">
          {translate("SETTINGS.SETTINGS")}
        </div>
      </div>
      <div className="text-black text-2xl font-semibold mt-3 mb-3">
        {translate("SETTINGS.CHANGE_PASSWORD")}
      </div>
      <ChangePasswordForm
        translate={translate}
        language={language}
        setNewPassword={setNewPassword}
        setIsOtpModalVisible={setIsOtpModalVisible}
        checkPassword={checkPassword}
        resetCountdown={resetCountdown}
        togglePasswordVisibility={togglePasswordVisibility}
        showPassword={showPassword}
        setShowPassword={setShowPassword}
      />

      <Modal
        title={
          <span className="text-2xl">{translate("SETTINGS.ENTER_OTP")}</span>
        }
        visible={isOtpModalVisible}
        onCancel={() => setIsOtpModalVisible(false)}
        footer={null}
      >
        <div className="text-gray-400 my-4">
          {translate("SETTINGS.WE_HAVE_SENT")}
        </div>
        <Row gutter={[12, 12]} dir="ltr">
          {otpRefs.map((ref, index) => (
            <Col span={6} key={index}>
              <input
                ref={ref}
                maxLength="1"
                onChange={(e) => handleChange(e.target.value, index)}
                value={otp[index]}
                type="text"
                className="text-center bg-white border border-[#EFEFEF] text-gray-900 sm:text-sm rounded-lg focus:ring-primary focus:border-primary block w-full p-2.5"
              />
            </Col>
          ))}
        </Row>
        <Button
          className="w-full text-white mt-4"
          onClick={handleOtpSubmit}
          color="primary"
        >
          {translate("SETTINGS.SUBMIT_OTP")}
        </Button>
        <div className="text-center text-gray-400 font-bold text-3xl mt-4">
          00:{String(seconds).padStart(2, "0")}
        </div>
      </Modal>
    </div>
  );
};

export default ChangePassword;
